import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TextThirdGen, TitleThirdGen } from '@signifyd/components'

interface Props {
  text: string
}

interface FormattedOrderReview {
  OR_CL?: {
    ck?: Array<ChecklistItem>
    cm?: string
  }
}

interface ChecklistItem {
  h: string
  i?: Array<string>
  t?: string
}

const FormattedOrderReviewChecklistItem: FC<Props> = ({ text }) => {
  const { t } = useTranslation()

  try {
    const data: FormattedOrderReview = JSON.parse(text)

    const checklist: Array<ChecklistItem> = data?.OR_CL?.ck ?? []
    const comment = data?.OR_CL?.cm

    return (
      <>
        <TitleThirdGen level={3}>
          {t('caseActivity.orderReviewChecklist')}
        </TitleThirdGen>
        {checklist.map((item, index: number) => (
          <div key={index}>
            <TextThirdGen weight="semibold">{`${item.h}: `}</TextThirdGen>
            {item.i && <TextThirdGen>{item.i.join(', ')}</TextThirdGen>}
            {item.t && <div>{item.t}</div>}
          </div>
        ))}
        {comment && (
          <div>
            <TextThirdGen weight="semibold">
              {t('caseActivity.comments')}
            </TextThirdGen>
            <div>{comment}</div>
          </div>
        )}
      </>
    )
  } catch {
    return <TextThirdGen>{t('caseActivity.invalidJSON')}</TextThirdGen>
  }
}

export default FormattedOrderReviewChecklistItem
