import { FC } from 'react'
import { Button, Flex } from 'antd'
import { Text, TitleThirdGen, useIsThirdGen } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { spacingMD } from '@signifyd/ant'
import { useStoreState } from 'store'
import { QUICKVIEW_KEYS } from 'store/search'
import styles from './QuickViews.less'

interface Props {
  onClick: (key: string) => void
}

const QuickViews: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation()
  const quickviews = useStoreState((state) => state.search.quickviews)
  const isThirdGen = useIsThirdGen()

  const Title = isThirdGen ? (
    <TitleThirdGen level={3}>
      {t('search.quickSearch.quickViews')}
    </TitleThirdGen>
  ) : (
    <Text size="md" transform="uppercase">
      {t('search.quickSearch.quickViews')}
    </Text>
  )

  return (
    <Flex gap={isThirdGen ? spacingMD : 0} vertical>
      {Title}

      <Flex vertical align="flex-start">
        {Object.entries(quickviews).map(([key, { title }]) => (
          <Button
            data-analytics-id={`quick-view-${key}`}
            data-test-id={`quickView${key}`}
            key={key}
            type="link"
            onClick={() => onClick(key)}
            className={styles.quickViewItem}
          >
            {t(`search.quickSearch.${title as QUICKVIEW_KEYS}`)}
          </Button>
        ))}
      </Flex>
    </Flex>
  )
}
export default QuickViews
