import { useFeatureFlags } from '@signifyd/components'

export const SECONDARY_MATCH_KEY = 'has-secondary-matches'

const useHasSecondaryMatches = (): boolean => {
  const {
    [SECONDARY_MATCH_KEY]: { isEnabledForEnvironment },
  } = useFeatureFlags([SECONDARY_MATCH_KEY])

  return isEnabledForEnvironment
}

export default useHasSecondaryMatches
