import { FC, useState, useRef } from 'react'
import { Text, Space, useIsThirdGen, TitleThirdGen } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { CaretRightOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import { Row, Col, Button, Collapse, Flex } from 'antd'
import { SEARCH_FIELD } from '@signifyd/http'
import { isEqual } from 'lodash'
import { Gutter } from 'antd/es/grid/row'
import { FilterState } from 'store/search'
import { defaultSearchState } from 'store/search/search.store'
import OrderDetailsCollapsePanel from 'core/components/OrderDetailsCollapsePanel'
import { AdvancedFiltersTree } from './AdvancedFiltersTree'
import OrderAmountFilter from './OrderAmountFilter'
import ScoreFilter from './ScoreFilter'
import ShippingMethodFilter from './ShippingMethodFilter'
import RecommendationStatusSelectFilter from './RecommendationStatusSelectFilter'
import GuaranteeStatusSelectFilter from './GuaranteeStatusSelectFilter'
import ClaimStatusSelectFilter from './ClaimStatusSelectFilter'
import SavedFilterInput from '../SavedFilterInput'
import styles from './FiltersContainer.less'
import ThirdGenScoreFilter from './ScoreFilter/ThirdGenScoreFilter'
import ThirdGenOrderAmountFilter from './OrderAmountFilter/ThirdGenOrderAmountFilter'

interface ColumnConfig {
  colSpan: number
  gutter: Gutter | [Gutter, Gutter]
}

interface Props {
  filters: FilterState
  updateFilter: (update: Partial<FilterState>) => void
  clearFilters: (e: any) => void
  setIsInvalidSavedFilter: (invalid: boolean) => void
  isInvalidSavedFilter: boolean
  setSaveFilterAs: (arg: string) => void
}

const FiltersContainer: FC<Props> = ({
  filters,
  updateFilter,
  clearFilters,
  setIsInvalidSavedFilter,
  isInvalidSavedFilter,
  setSaveFilterAs,
}) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()
  const [activeKey, setActiveKey] = useState<string | Array<string>>([])
  const scrollRef = useRef<HTMLDivElement>(null)

  const isDefaultFilters = isEqual(defaultSearchState.filters, {
    ...filters,
    normalizedPurchaseCreatedAt:
      defaultSearchState.filters.normalizedPurchaseCreatedAt,
  })

  const columnConfig: ColumnConfig = isThirdGen
    ? { colSpan: 6, gutter: 16 }
    : { colSpan: 8, gutter: [24, 16] }

  return (
    <Collapse
      bordered={false}
      style={{ background: 'none' }}
      activeKey={activeKey}
      className={styles.collapse}
      onChange={setActiveKey}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          style={{ fontSize: '16px' }}
          data-test-id="toggleShowHide"
          data-analytics-id={`${isActive ? 'expand' : 'close'}-filters-panel`}
          rotate={isActive ? 90 : 0}
        />
      )}
      destroyInactivePanel
    >
      <OrderDetailsCollapsePanel
        header={
          <Flex align="center" className={styles.header}>
            {isThirdGen ? (
              <TitleThirdGen level={3} data-test-id="filtersTitle">
                {activeKey.length ? t('filters.hide') : t('filters.show')}
              </TitleThirdGen>
            ) : (
              <Text data-test-id="filtersTitle" size="md" transform="uppercase">
                {activeKey.length ? t('filters.hide') : t('filters.show')}
              </Text>
            )}
            {!isDefaultFilters && (
              <Button
                type="link"
                onClick={clearFilters}
                data-test-id="clearFilters"
                data-analytics-id="clear-filters-button"
              >
                {t('filters.clearFilters')}
              </Button>
            )}
          </Flex>
        }
        key="filters"
        style={{
          border: 0,
          padding: 0,
        }}
      >
        <div ref={scrollRef}>
          <Form layout="vertical">
            <Row gutter={columnConfig.gutter}>
              <Col xs={{ span: 13 }} lg={{ span: columnConfig.colSpan }}>
                <RecommendationStatusSelectFilter
                  value={filters.recommendedDecisionDisposition}
                  updateFilterFn={updateFilter}
                  scrollRef={scrollRef}
                />
              </Col>
              <Col xs={{ span: 13 }} lg={{ span: columnConfig.colSpan }}>
                <GuaranteeStatusSelectFilter
                  value={filters.guaranteeDisposition}
                  updateFilterFn={updateFilter}
                  scrollRef={scrollRef}
                />
              </Col>
              <Col xs={{ span: 13 }} lg={{ span: columnConfig.colSpan }}>
                <ClaimStatusSelectFilter
                  value={[...filters.claimStatus, ...filters.claimDisposition]}
                  updateFilterFn={updateFilter}
                  scrollRef={scrollRef}
                />
              </Col>
              <Col xs={{ span: 13 }} lg={{ span: columnConfig.colSpan }}>
                {isThirdGen ? (
                  <ThirdGenOrderAmountFilter
                    currentFilter={filters[SEARCH_FIELD.orderTotalAmount]}
                    updateFilterFn={updateFilter}
                  />
                ) : (
                  <OrderAmountFilter
                    currentFilter={filters[SEARCH_FIELD.orderTotalAmount]}
                    updateFilterFn={updateFilter}
                  />
                )}
              </Col>
              <Col xs={{ span: 13 }} lg={{ span: columnConfig.colSpan }}>
                {isThirdGen ? (
                  <ThirdGenScoreFilter
                    currentFilter={filters[SEARCH_FIELD.signifydScore]}
                    updateFilterFn={updateFilter}
                  />
                ) : (
                  <ScoreFilter
                    currentFilter={filters[SEARCH_FIELD.signifydScore]}
                    updateFilterFn={updateFilter}
                  />
                )}
              </Col>
              <Col xs={{ span: 13 }} lg={{ span: columnConfig.colSpan }}>
                <ShippingMethodFilter
                  value={filters[SEARCH_FIELD.shippingMethod]}
                  updateFilterFn={updateFilter}
                  scrollRef={scrollRef}
                />
              </Col>
              <Col xs={{ span: 13 }} lg={{ span: columnConfig.colSpan }}>
                <Form.Item label={t('filters.advanced.label')}>
                  <AdvancedFiltersTree
                    filters={filters}
                    updateFilterFn={updateFilter}
                    scrollRef={scrollRef}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>

        <Space size="sm" />

        <SavedFilterInput
          isDefaultFilters={isDefaultFilters}
          setIsInvalidSavedFilter={setIsInvalidSavedFilter}
          isInvalidSavedFilter={isInvalidSavedFilter}
          updateHandler={setSaveFilterAs}
        />
      </OrderDetailsCollapsePanel>
    </Collapse>
  )
}

export default FiltersContainer
