import { FC, useState } from 'react'
import { Flex, Table, Tag, Tooltip } from 'antd'
import {
  ExternalLink,
  i18nInstance,
  Space,
  TitleThirdGen,
} from '@signifyd/components'
import { formatCurrencyNumber, toTitleCase } from '@signifyd/utils'
import { InvestigationInfo } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import NoInformation from 'core/components/NoInformation'
import { spacingSM } from '@signifyd/ant'
import { ColumnProps } from 'antd/es/table'
import { CaretUpOutlined } from '@ant-design/icons'
import styles from './OrderSummary.less'
import { getSortedProducts } from './OrderSummary.utils'

const { t } = i18nInstance

const PAGINATION_SIZE = 10

export interface OrderProduct {
  itemName?: string
  itemPrice?: string | number
  itemQuantity?: number
  itemUrl?: string
  shippingMethod?: string
}

interface ProductNameProps {
  product: OrderProduct
}

const ProductName: FC<ProductNameProps> = ({ product }) => {
  if (product.itemUrl) {
    const { itemName = '', itemUrl } = product

    return (
      <Tooltip title={itemName} placement="topLeft">
        <ExternalLink url={itemUrl} target="_blank" className={styles.itemName}>
          {itemName}
        </ExternalLink>
      </Tooltip>
    )
  }

  return (
    <Tooltip title={product.itemName} placement="topLeft">
      <span className={styles.itemName}>{product.itemName}</span>
    </Tooltip>
  )
}

interface Props {
  caseDetails: InvestigationInfo
}

type ColumnConfigs = Array<ColumnProps<OrderProduct>>

interface ColumnOptions {
  descendingOrder: boolean
  onSortByPrice: () => void
  showShippingColumn: boolean
}

const getColumnConfigs = ({
  descendingOrder,
  onSortByPrice,
  showShippingColumn,
}: ColumnOptions): ColumnConfigs => {
  const columnConfig: ColumnConfigs = [
    {
      title: t('caseSummary.order.name'),
      dataIndex: 'itemName',
      render: (_, product) => <ProductName product={product} />,
    },
    {
      // This table has a pretty custom sorting pattern that doesn't really play with ant ecosystem
      // todo: improve this in the future
      title: (
        <Flex justify="space-between">
          {t('caseSummary.order.price')}

          <CaretUpOutlined
            rotate={descendingOrder ? 180 : 0}
            onClick={onSortByPrice}
          />
        </Flex>
      ),
      dataIndex: 'itemPrice',
      render: (itemPrice) => formatCurrencyNumber(itemPrice ?? ''),
    },
    {
      title: t('caseSummary.order.quantity'),
      dataIndex: 'itemQuantity',
    },
  ]

  if (showShippingColumn) {
    columnConfig.push({
      title: t('caseSummary.order.shippingMethod'),
      dataIndex: 'shippingMethod',
    })
  }

  return columnConfig
}

export const OrderSummaryThirdGen: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()
  const [descendingOrder, setDescendingOrder] = useState(true)

  const { orderChannel, receivedBy } = caseDetails

  const sortedProducts = getSortedProducts(caseDetails, descendingOrder)

  const hasMatchingShipment = sortedProducts.some(
    (product) => product.shippingMethod
  )

  const agentName = t('caseSummary.order.agent', {
    name: receivedBy,
  })

  const enableProductPagination = sortedProducts.length > PAGINATION_SIZE

  return (
    <>
      <TitleThirdGen level={2}>
        <Flex gap={spacingSM} align="center">
          {t('caseSummary.order.title')}
          {orderChannel && <Tag>{toTitleCase(orderChannel)}</Tag>}
          {receivedBy && (
            <Tooltip title={agentName}>
              <Tag className={styles.agentTag}>{agentName}</Tag>
            </Tooltip>
          )}
        </Flex>
      </TitleThirdGen>
      <Space size="sm" />
      <Table
        dataSource={sortedProducts}
        columns={getColumnConfigs({
          descendingOrder,
          onSortByPrice: () => {
            setDescendingOrder(!descendingOrder)
          },
          showShippingColumn: hasMatchingShipment,
        })}
        size="small"
        bordered
        pagination={
          enableProductPagination
            ? {
                defaultPageSize: PAGINATION_SIZE,
              }
            : false
        }
      />

      {!sortedProducts?.length && <NoInformation />}
    </>
  )
}

export default OrderSummaryThirdGen
