import { RuntimeSeller } from 'pages/CaseReviewPage/containers/OrderDetails/components/SellerDetailsThirdGen/SellerDetails.types'

export enum MENU_ICON {
  GoogleMapIcon = 'GoogleMapIcon',
  GoogleSearchIcon = 'GoogleSearchIcon',
  SigLogoSmall = 'SigLogoSmall',
  PiplIcon = 'PiplIcon',
  EkataIcon = 'EkataIcon',
  WhitepagesIcon = 'WhitepagesIcon',
  LinkedinIcon = 'LinkedinIcon',
}

export type MenuItem = {
  icon: MENU_ICON
  text: string
  url: string
}

export interface DeepLinkFactoryProps {
  seller: RuntimeSeller
  field: keyof RuntimeSeller
}

export type DeepLinkFactory = (props: DeepLinkFactoryProps) => Array<MenuItem>
