import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  GuaranteeIcon,
  ShieldResubmit,
  useIsThirdGen,
} from '@signifyd/components'
import moment from 'moment-timezone'
import { colorBondi } from '@signifyd/colors'
import { useStoreState } from 'store'
import ResubmitGuaranteeModal from 'pages/CaseReviewPage/containers/ResubmitGuaranteeModal'
import { STATIC_NOTES } from 'store/constants'
import { useResubmitStatus } from 'core/hooks/useResubmitStatus'
import { InvestigationInfo } from '@signifyd/http'
import styles from './ResubmitGuaranteeButton.less'
import ActionButton from '../ActionButton'
import ActionIconButton from '../ActionIconButton/ActionIconButton'

interface Props {
  caseDetails: InvestigationInfo
}

export const ResubmitGuaranteeButton: FC<Props> = ({ caseDetails }) => {
  const isThirdGen = useIsThirdGen()

  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)

  const { isLoading, data: resubmitStatus } = useResubmitStatus({
    investigationId: caseDetails.investigationId,
  })

  const [reReviewNote] = useStoreState(
    (state) => state.currentCase.notes
  ).filter(({ text }) => text === STATIC_NOTES.CASE_RE_REVIEW)

  const reSubmittedOn = reReviewNote
    ? `${t('caseActions.submittedOn')} ${moment(reReviewNote.createdAt).format(
        t('caseActions.dateTimeFormat')
      )}`
    : undefined

  return (
    <>
      {isThirdGen && !resubmitStatus?.hidden ? (
        <ActionIconButton
          onClick={() => setModalVisible(true)}
          actionState={resubmitStatus}
          isLoading={isLoading}
          className={styles.submitGuaranteeIcon}
          Icon={ShieldResubmit}
          tooltip={t('caseActions.reSubmitGuarantee.tooltip')}
          subText={reSubmittedOn}
          testId="resubmitGuaranteeButton"
        />
      ) : (
        <ActionButton
          onClick={() => setModalVisible(true)}
          actionState={resubmitStatus}
          isLoading={isLoading}
          className={styles.submitGuaranteeIcon}
          icon={
            <GuaranteeIcon
              fill={resubmitStatus?.disabled ? undefined : colorBondi}
            />
          }
          text={t('caseActions.reSubmitGuarantee.text')}
          tooltip={t('caseActions.reSubmitGuarantee.tooltip')}
          subText={reSubmittedOn}
          testId="resubmitGuaranteeButton"
        />
      )}

      <ResubmitGuaranteeModal
        caseDetails={caseDetails}
        visible={modalVisible}
        closeModal={() => setModalVisible(false)}
      />
    </>
  )
}

export default ResubmitGuaranteeButton
