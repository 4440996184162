import { FC, useContext } from 'react'
import { nanoid } from 'nanoid'
import { Space, useIsThirdGen } from '@signifyd/components'
import PageSizeContext, { PAGE_SIZE } from 'core/components/PageSizeContext'
import { useStoreState } from 'store'
import { isTransportAirline } from 'core/utils/isTransportAirline/isTransportAirline'
import { useCaseEntries } from 'core/hooks/useCaseEntries'
import isEventTicketing from 'core/utils/isEventTicketing'
import EventDetails from 'pages/CaseReviewPage/containers/OrderDetails/components/EventDetails'
import CaseIntelligence from '../CaseIntelligence'
import CaseClaimsBanner from '../CaseClaimsBanner'
import CaseSummary from '../CaseSummary'
import CaseDetails from './components/CaseDetails'
import SellerDetails from './components/SellerDetails'
import MembershipDetails from './components/MembershipDetails/MembershipDetails'
import TravelDetails from './components/TravelDetails'
import CaseSummaryThirdGen from '../CaseSummary/CaseSummaryThirdGen'
import CaseIntelligenceThirdGen from '../CaseIntelligenceThirdGen'
import CaseDetailsThirdGen from './components/CaseDetailsThirdGen/CaseDetailsThirdGen'
import CaseClaimsBannerThirdGen from '../CaseClaimsBanner/thirdGen/CaseClaimsBannerThirdGen'
import TravelDetailsThirdGen from './components/TravelDetails/thirdGen/TravelDetailsThirdGen'
import SellerDetailsThirdGen from './components/SellerDetailsThirdGen/SellerDetailsThirdGen'
import MembershipCard from './components/MembershipDetailsThirdGen/MembershipCard'

const OrderDetails: FC = () => {
  const pageSize = useContext(PageSizeContext)
  const isLarge = pageSize === PAGE_SIZE.LG_AND_LARGER

  const { details, order } = useStoreState((state) => state.currentCase)
  const { data: entries } = useCaseEntries(details?.investigationId)
  const isThirdGen = useIsThirdGen()

  if (!details || !entries) {
    return null
  }

  const sellers = details.sellers.map((seller) => ({
    ...seller,
    runtimeId: nanoid(),
  }))

  const isAirlineOrder = isTransportAirline(details.products)
  const isEventTicketingOrder = isEventTicketing(details.products)

  return (
    <div data-test-id="orderDetailsDisplay">
      {isThirdGen ? (
        <CaseClaimsBannerThirdGen caseDetails={details} />
      ) : (
        <CaseClaimsBanner caseDetails={details} />
      )}
      {isThirdGen ? (
        <CaseIntelligenceThirdGen isLarge={isLarge} caseDetails={details} />
      ) : (
        <CaseIntelligence isLarge={isLarge} caseDetails={details} />
      )}

      <Space size="sm" />
      {isThirdGen ? (
        <CaseSummaryThirdGen
          orderDetails={order}
          caseEntries={entries}
          caseDetails={details}
        />
      ) : (
        <CaseSummary
          orderDetails={order}
          caseEntries={entries}
          caseDetails={details}
        />
      )}

      {isAirlineOrder && isThirdGen && (
        <>
          <Space size="sm" />
          <TravelDetailsThirdGen investigation={details} />
        </>
      )}
      {isAirlineOrder && !isThirdGen && (
        <>
          <Space size="sm" />
          <TravelDetails investigation={details} />
        </>
      )}
      {isEventTicketingOrder && (
        <>
          <Space size="sm" />
          <EventDetails caseDetails={details} />
        </>
      )}
      <Space size="sm" />
      {isThirdGen ? (
        <CaseDetailsThirdGen caseEntries={entries} caseDetails={details} />
      ) : (
        <CaseDetails caseEntries={entries} caseDetails={details} />
      )}
      <Space size="sm" />
      {isThirdGen ? (
        <SellerDetailsThirdGen
          sellers={sellers}
          currency={details.currency ?? 'USD'}
        />
      ) : (
        <SellerDetails sellers={sellers} currency={details.currency ?? 'USD'} />
      )}
      <Space size="sm" />
      {details.memberships?.length > 0 && (
        <>
          {isThirdGen ? (
            <MembershipCard memberships={details.memberships} />
          ) : (
            <>
              <Space size="sm" />
              <MembershipDetails memberships={details.memberships} />
            </>
          )}
        </>
      )}
      <Space size="sm" />
    </div>
  )
}

export default OrderDetails
