import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CaseEntriesItem,
  CaseEntryNetworkDetails,
  InvestigationInfo,
  PREDICTION_LIST_TYPE,
} from '@signifyd/http'
import { Descriptions } from 'antd'
import { uniqBy } from 'lodash'
import { CaseEntriesResolvedState } from 'store/currentCase/types/case.entries.types'
import CopyWrapper from 'core/components/CopyWrapper'
import OrderAttributeLists from 'core/components/OrderAttributeLists'
import NoInformation from 'core/components/NoInformation'
import SeederLabel from 'pages/CaseReviewPage/components/SeederLabel'
import { DescriptionsItemType } from 'antd/es/descriptions'
import { defaultDescriptionProps } from 'core/constants'
import DeepLinkDropdown from '../components/DeepLinkDropdown'
import { MENU_SECTION } from '../components/DeepLinkDropdown/DeepLinkDropdown.types'

export interface NetworkProps {
  caseEntries: CaseEntriesResolvedState
  caseDetails: InvestigationInfo
}

const Network: FC<NetworkProps> = ({ caseEntries, caseDetails }) => {
  const { t } = useTranslation()
  const { emailDomains, purchaseIP, ipDomain } = caseEntries

  const { browserIpAddress } = caseDetails

  const deDupedDomains = uniqBy(emailDomains, 'seederUuid')

  const noInfo = !purchaseIP && !emailDomains?.length && !ipDomain
  const sectionName = t('pages.caseReview.details.network')

  const items = [
    purchaseIP?.entityName && {
      label: <SeederLabel label={t('pages.caseReview.details.purchaseIP')} />,
      children: (
        <>
          <CopyWrapper text={purchaseIP.entityName}>
            <DeepLinkDropdown section={MENU_SECTION.network}>
              {purchaseIP.entityName}
            </DeepLinkDropdown>
          </CopyWrapper>
          {browserIpAddress && (
            <OrderAttributeLists
              type={PREDICTION_LIST_TYPE.IP_ADDRESS}
              value={browserIpAddress}
              analyticsId="purchase-IP"
            />
          )}
        </>
      ),
    },
    ...deDupedDomains.map(
      (emailDomain: CaseEntriesItem<CaseEntryNetworkDetails>) => ({
        label: t('pages.caseReview.details.emailDomain'),
        children: (
          <CopyWrapper text={emailDomain.entityName}>
            {emailDomain.entityName}
          </CopyWrapper>
        ),
      })
    ),
    ipDomain?.entityName && {
      label: t('pages.caseReview.details.ipDomain'),
      children: (
        <CopyWrapper text={ipDomain.entityName}>
          {ipDomain.entityName}
        </CopyWrapper>
      ),
    },
    noInfo && {
      label: '',
      labelStyle: { display: 'none' },
      children: <NoInformation />,
    },
  ].filter((item) => !!item) as Array<DescriptionsItemType>

  return (
    <Descriptions
      title={sectionName}
      items={items}
      {...defaultDescriptionProps}
    />
  )
}

export default Network
