import { useTranslation } from 'react-i18next'
import { Card, Button, Flex } from 'antd'
import { useToggle } from 'core/utils/useToggle'
import { spacingMD } from '@signifyd/ant'
import { CardHeaderThirdGen } from '../CardHeader/CardHeaderThirdGen'

export interface ShowMoreContainerProps<T> {
  cardTitle: string
  data: Array<T>
  renderItem(args: T): JSX.Element
  idPrefix: string
  defaultToShow?: number
}

export const ShowMoreContainerThirdGen = <T,>({
  renderItem,
  data,
  cardTitle,
  idPrefix,
  defaultToShow = 6,
}: ShowMoreContainerProps<T>): JSX.Element => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.caseReview.common',
  })
  const { value: shouldShowAll, toggle: toggleShowAll } = useToggle()

  const numberMoreThanMaxItems = data.length - defaultToShow
  const dataToShow = shouldShowAll ? data : data.slice(0, defaultToShow)

  const buttonId = shouldShowAll
    ? `${idPrefix}CollapseButton`
    : `${idPrefix}ExpandButton`

  const buttonText = shouldShowAll
    ? t('showLess')
    : t('showMore', {
        count: numberMoreThanMaxItems,
      })

  return (
    <Card title={<CardHeaderThirdGen title={cardTitle} />}>
      <Flex
        vertical
        align="flex-start"
        data-test-id={`${idPrefix}Wrapper`}
        gap={spacingMD}
      >
        {dataToShow.map((item) => renderItem(item))}
      </Flex>

      {numberMoreThanMaxItems > 0 && (
        <Button
          data-test-id={buttonId}
          data-analytics-id={buttonId}
          onClick={toggleShowAll}
          type="link"
        >
          {buttonText}
        </Button>
      )}
    </Card>
  )
}
