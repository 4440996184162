import { FC, ReactNode } from 'react'
import {
  Calendar,
  Case,
  ExternalLink,
  Fingerprint,
  FolderOpen,
  Store,
  TextThirdGen,
  TitleThirdGen,
  getCaseReviewUrl,
} from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { InvestigationInfo } from '@signifyd/http'
import { Popover, Tooltip, Flex } from 'antd'
import { useStoreState } from 'store'
import FormattedDate from 'core/components/FormattedDate'
import { spacingMD } from '@signifyd/ant'
import { colorSlate } from '@signifyd/colors'
import styles from './CaseHeaderDetails.less'

interface Props {
  caseDetails: InvestigationInfo
}

const MAX_CHECKOUT_LIMIT = 16

const DetailsLabel: FC<{ Icon: ReactNode; text: ReactNode }> = ({
  Icon,
  text,
}) => {
  return (
    <Flex gap={spacingMD} align="center" className={styles.detailsLabel}>
      {Icon}
      {text}
    </Flex>
  )
}

export const CaseHeaderDetails: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()
  const user = useStoreState((state) => state.user.currentUser)
  const caseReviewUrl = getCaseReviewUrl()

  const { checkoutToken, investigationId } = caseDetails
  const checkoutTokenTooLong =
    checkoutToken && checkoutToken?.length > MAX_CHECKOUT_LIMIT

  return (
    <Flex className={styles.container}>
      <Tooltip
        title={
          <TextThirdGen weight="semibold">
            {t('pages.caseReview.header.caseStatus', {
              status: t(`pages.caseReview.header.${caseDetails.caseStatus}`),
            })}
          </TextThirdGen>
        }
        mouseEnterDelay={0.5}
      >
        <div>
          <DetailsLabel
            Icon={
              caseDetails?.caseStatus === 'OPEN' ? (
                <FolderOpen fill={colorSlate} />
              ) : (
                <Case fill={colorSlate} />
              )
            }
            text={
              <TitleThirdGen
                level={3}
                className={styles.statusText}
                data-test-id={`caseDetailsLabel-${caseDetails.caseStatus}`}
              >
                {user?.isAdmin ? (
                  <ExternalLink url={`${caseReviewUrl}/${investigationId}`}>
                    {investigationId}
                  </ExternalLink>
                ) : (
                  investigationId
                )}
              </TitleThirdGen>
            }
          />
        </div>
      </Tooltip>
      <div className={styles.divider} />

      <DetailsLabel
        Icon={<Calendar fill={colorSlate} />}
        text={
          <TitleThirdGen level={3} className={styles.statusText}>
            <FormattedDate date={caseDetails.normalizedPurchaseCreatedAt} />
          </TitleThirdGen>
        }
      />

      {caseDetails.teamName && (
        <>
          <div className={styles.divider} />
          <DetailsLabel
            Icon={<Store fill={colorSlate} />}
            text={
              <TitleThirdGen level={3} className={styles.statusText}>
                {caseDetails.teamName}
              </TitleThirdGen>
            }
          />
        </>
      )}

      {checkoutToken && (
        <>
          <div className={styles.divider} />
          <DetailsLabel
            Icon={<Fingerprint fill={colorSlate} />}
            text={
              <TitleThirdGen level={3} className={styles.statusText}>
                {checkoutTokenTooLong ? (
                  <>
                    {checkoutToken.substr(0, MAX_CHECKOUT_LIMIT)}...
                    <Popover
                      content={checkoutToken}
                      title={t('pages.caseReview.header.checkoutToken')}
                      trigger="click"
                    >
                      <span> {t('pages.caseReview.header.view')}</span>
                    </Popover>
                  </>
                ) : (
                  checkoutToken
                )}
              </TitleThirdGen>
            }
          />
        </>
      )}
    </Flex>
  )
}

export default CaseHeaderDetails
