import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { EyeInvisibleOutlined } from '@ant-design/icons'
import { TextThirdGen, TitleThirdGen } from '@signifyd/components'
import { Flex } from 'antd'
import { spacingMD, spacingXS } from '@signifyd/ant'
import styles from './ThirdGenTableColumnSelectDrawer.less'

const ThirdGenHiddenColumnsEmpty: FC = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.results.columnSelect.empty',
  })

  return (
    <Flex
      vertical
      className={styles.hiddenEmpty}
      align="center"
      gap={spacingMD}
    >
      <EyeInvisibleOutlined className={styles.emptyIcon} />
      <Flex vertical gap={spacingXS}>
        <TitleThirdGen level={2}>{t('header')}</TitleThirdGen>
        <TextThirdGen className={styles.emptyText}>{t('value')}</TextThirdGen>
      </Flex>
    </Flex>
  )
}

export default ThirdGenHiddenColumnsEmpty
